.history__card {
  border-bottom: 0.5px solid #e9e9e9;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
.history__card.selected {
  background-color: #f2f0f8ea;
}
.history__card:hover {
  cursor: pointer;
  background-color: #f2f0f7c8;
}
.history__card_url {
  font-size: 14px;
  font-weight: 500;
}

.history__card_date {
  color: #929292;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.history__card_chart {
  width: 70px;
  height: 70px;
}
.history__card_text_container {
  flex: 1;
  max-width: 180px;
  word-break: break-all;
}
.error__tooltip_container {
  height: 25px;
  /* width: 35px; */
  background-color: rgb(241, 200, 200);
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #b80303;
}
.error__tooltip_container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  box-sizing: border-box;
  gap: 3px;
  font-size: 10px;
  color: #b80303;
}
