.metric_ref_container {
  border: 0.5px solid var(--grey-color);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.metric_ref_table_container {
  background-color: #a3c0f930;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  /* box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15); */
}
.metric_ref_title {
  font-weight: 700;
  font-size: 16px;
}
.metric_ref_content_main {
  font-size: 14px;
  margin: 20px 0px;
  color: #3d3c3c;
  /* font-weight: 600; */
}
.metric_ref_content {
  font-size: 14px;
  color: #4f4e4e;
}
.metric_ref_head_row td {
  font-weight: 700;
  color: rgb(73, 72, 72);
}
.metric_ref_head_row td:nth-child(2) {
  text-align: center !important;
}
.metric_ref_col_1 {
  max-width: 400px;
  border-bottom: unset !important;
}
.metric_ref_col_2 {
  border-bottom: unset !important;
  text-align: center !important;
  font-weight: 500 !important;
  color: #3a2e6a;
}
.metric_ref_name {
  font-weight: 700;
  color: #3b1572;
}
.metric_ref_head_cell_1 {
  display: flex !important;
  gap: 5px;
}
.metric_ref_head_cell_1_label {
  margin-bottom: 3px;
  padding-top: 3px;
}
.metric_ref_scale {
  font-size: 16px !important;
  margin-right: 5px;
  color: #3a2e6a !important;
  margin-bottom: -2px !important;
}
.metric_ref_equalizer {
  margin-bottom: -4px;
  margin-right: 5px;
}
