.weight_container {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 12px;
  color: #e3c513;
}
.value_div {
  width: 16px;
}
.metric__sub_title {
  text-transform: capitalize;
  margin: 20px 0px;
  font-weight: 700;
  font-size: 20px;
  color: #553f87;
}
