.advanced__reports__container {
  text-align: center;
  border-bottom: 1px solid #e5e5e7;
  /* outline: var(--grey-shade) solid 1px; */
  /* padding-top: 1rem; */
}

.loader__container {
  text-align: center;
  padding: 5rem;
}

.advanced__reports__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
}

.please__login__text {
  padding: 0.5em 0;
  color: var(--grey-text-color);
  font-size: 14px;
}

.login__button {
  margin: 0.5em !important;
  background-color: var(--primary-color) !important;
  text-transform: none !important;
}

.box__container {
  width: 100%;
  /* padding-top: 1rem; */
}

.sample__report__example {
  background-color: #e9e2f0;
  text-align: center;
  margin: 1rem 0 2rem 0;
  padding: 1rem 0 1rem 0;
}

.sample__report__example p {
  margin: 0;
}

.result__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--black-variant);
  flex-wrap: wrap;
}

.img {
  max-width: 340px;
  max-height: 240px;
}

.separator {
  background: #e0e0e0;
  width: 1px;
  height: 96px;
}

.label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.loader__tab_name {
  display: flex;
  align-items: center;
}

.api_error_container {
  background: #fbfbc3;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #e0e303;
  text-align: left;
}
.api_error_main_head {
  font-size: 15px;
  font-weight: 600;
  color: #a89002;
  margin-bottom: 8px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.api_error_label {
  font-weight: 600;
  font-size: 13px;
  color: rgb(57, 59, 57);
}
.api_error_message {
  font-size: 13px;
  color: grey;
}
.api_error_main_head_icon {
  font-size: 18px;
}
