.tips__container {
  border: 1px solid var(--primary-color-light-variant);
  border-width: 1px;
  border-bottom-width: 5px;
  border-style: solid;
  border-image: linear-gradient(var(--primary-color), var(--primary-color-light-variant)) 30;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 40vw; */
  min-height: 100px;
  text-align: center;
  margin: 10vh auto 0 auto;
}

.icon {
  color: var(--primary-color);
}
