.metric__container {
  border-top: 1px solid #ebebeb;
}

.inner__container {
  display: grid;
  grid-template-columns: 32px 328px 1fr;
  align-items: center;
  padding: 8px;
  text-align: left;
}

.icon {
  content: '';
  display: inline-block;
  margin: 0 14px 0 6px;
}

.success__icon {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  color: var(--pass-text-color);
  background: var(--pass-text-color);
}

.error__icon {
  width: auto;
  height: auto;
  border-left: 5.6px solid transparent;
  border-right: 5.6px solid transparent;
  border-bottom: 12px solid var(--fail-fill-color);
}

.average__icon {
  width: 10px;
  height: 10px;
  background: var(--average-fill-color);
}

.title {
  word-break: break-word;
  text-transform: capitalize;
  color: var(--grey-text-color);
  font-size: 14px;
}

.value {
  font-size: 25px;
  margin: 4px 0;
  white-space: nowrap;
  grid-column-start: 2;
  /* color: var(--pass-text-color); */
}
