.container {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  border-bottom: 1px solid var(--grey-color);
  flex-wrap: wrap;
}

.icon__container {
  display: inline-flex;
  gap: 1em;
  align-items: center;
  cursor: pointer;
}
.logo_container {
  width: 200px;
}
.logo_container img {
  height: 45px;
}
.login__button {
  color: var(--primary-color) !important;
  text-transform: none !important;
  border-color: var(--primary-color) !important;
}

.link__container {
  flex: 1;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.link__container a {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 5px;
  justify-content: center;
  color: #392d69;
  font-size: 12px;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}
.link__container p {
  margin: 0px;
}
.selected {
  background: #f3f1f6;
}
