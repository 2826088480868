.metric__container {
  padding-top: 2rem;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  margin-bottom: 8px;
}
.metrcs__main_container {
  border: 0.5px solid var(--grey-color);
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}
.metric_id_col {
  display: flex;
  align-items: center;
  gap: 10px;
}
