.button {
  height: 40px;
  background-color: var(--primary-color) !important;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-light-variant)
  ) !important;
  text-transform: none !important;
}
.inputbox__container {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  position: relative;
  margin-bottom: 35px;
}
.modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: 'absolute' as 'absolute';
  background-color: rgb(244, 242, 246);
  width: 60%;
  height: 650px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 45px;
  box-sizing: border-box;
  overflow: auto;
}
.loader__container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0px 60px;
  box-sizing: border-box;
}

.error__component {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.error_message__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.button__controllers {
  display: flex;
  justify-content: right;
}
.error__icon {
  color: red;
}

.wizard__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wizard_content {
  flex: 1;
  overflow: auto;
  margin-bottom: 20px;
}
.wizard_controls {
  display: flex;
  gap: 10px;
  justify-content: right;
}

.wizard__title {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
}
.wizard__info {
  font-size: 12px;
  color: rgb(71, 70, 70);
  margin-bottom: 20px;
}
.wizard__subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  color: rgb(69, 69, 69);
}
.wizard_list_item {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 8px;
  font-size: 13px;
  margin-left: 15px;
}
.wizard_list_icons {
  color: #543e86;
  font-size: 18px;
}
.wizard_list_label {
  font-size: 13px;
  color: #050110;
  font-weight: 600;
}

.sm_outline__button {
  color: #543e86 !important;
  border-color: #543e86 !important;
  width: 90px;
  text-transform: none !important;
}
.sm_cancel__button {
  background-color: #cabfe3 !important;
  color: #1b122f !important;
  width: 90px;
  text-transform: none !important;
}

.sm_confirm__button {
  background-color: #543e86 !important;
  background-image: linear-gradient(to right, #543e86, #9b6b92) !important;
  text-transform: none !important;
  color: whitesmoke !important;
  width: 90px;
}
.wizard_empty_list_msg {
  font-weight: 800;
  color: grey;
}
.wizard_empty_confirmation_msg {
  font-weight: 600;
  background-color: rgb(231, 228, 228);
  padding: 10px;
  border-radius: 5px;
  color: grey;
  font-size: 13px;
}

.others_section {
  margin: 20px 0px;
  background: rgb(237, 237, 246);
  padding: 15px;
  box-sizing: border-box;
  margin-right: 10px;
}
.question_container {
  background: rgb(237, 237, 246);
  overflow: auto;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.question_string {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  flex: 1;
}
.sm_load__button {
  background-color: #543e86 !important;
  background-image: linear-gradient(to right, #543e86, #9b6b92) !important;
  text-transform: none !important;
  color: whitesmoke !important;
  width: 100px;
  float: right;
}
.disabled_button {
  opacity: 0.5;
  color: #dcd5ec !important;
}
.question_replay_icon {
  font-size: 16px;
  margin-right: 5px;
}
.routes_loader {
  display: flex;
}
.circular__loader {
  font-size: 14px;
}

.error__msg_container {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 13px;
  background-color: rgba(255, 0, 0, 0.086);
  color: rgb(101, 5, 5);
  padding: 8px;
  /* border: 1px solid rgba(101, 5, 5, 0.407); */
  border-radius: 5px;
  margin-top: 12px;
}
.error__msg_icon {
  font-size: 14px;
}
.additional_url_container {
  border-top: 1px solid #d2cdcd;
}
.wizard_route_list {
  overflow: auto;
  max-height: 340px;
  margin-top: 10px;
  box-sizing: border-box;
}
.wizard_route_info_title {
  margin-bottom: 5px;
  margin-top: 15px;
}
.inputbox {
  /* border: 1px solid var(--primary-outline-color); */
  height: 40px;
  flex-grow: 1;
  outline: none;
}

.url_error_box {
  position: absolute;
  top: 45px;
  font-size: 10px;
  color: #d32e2e;
}

.generate_advanced_section {
  display: flex;
  background: #f2eef8;
  margin: 15px 0px;
  padding: 10px 20px;
  border-radius: 5px;
  align-items: center;
}
.generate_advanced_message {
  flex: 1;
  color: #1b122f;
  font-size: 14px;
  font-weight: 600;
}
.generate_advanced_button {
  text-transform: unset !important;
  background: #5c438f !important;
}
