.vertical-loader ul {
  display: flex;
  padding-left: 15px;
}

.vertical-loader ul li {
  list-style: none;
  width: 3px;
  height: 10px;
  background: #543e86;
  margin: 0px 2px;
  animation: animate 0.7s infinite alternate;
}

@keyframes animate {
  0% {
    transform: scaleY(1);
  }

  25% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(1);
  }

  75% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(2);
  }
}

.vertical-loader ul li:nth-child(1) {
  animation-delay: 0.1s;
  opacity: 0.8;
}

.vertical-loader ul li:nth-child(2) {
  animation-delay: 0.2s;
  opacity: 0.6;
}

.vertical-loader ul li:nth-child(3) {
  animation-delay: 0.3s;
  opacity: 0.5;
}

.vertical-loader ul li:nth-child(4) {
  animation-delay: 0.4s;
  opacity: 0.7;
}

.vertical-loader ul li:nth-child(5) {
  animation-delay: 0.5s;
  opacity: 0.8;
}

.vertical-loader ul li:nth-child(6) {
  animation-delay: 0.6s;
  opacity: 1;
}
