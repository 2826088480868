.page__404__parent {
  height: 100vh;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
}

.page_404_container {
  background-image: url('../../assets/images/NotFound.gif');
  height: 50%;
  width: 100%;
  background-position: center;
}

.text__container {
  text-align: center;
}

.text__container h3 {
  font-size: 25px;
}