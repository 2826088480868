.container {
  padding: 0 30px;
  width: 890px;
  overflow: auto;
  margin-top: 20px;
}

.container > :first-child {
  font-size: 18px;
  margin-top: 12px;
}

.inputbox {
  border: 1px solid var(--primary-outline-color);
  height: 40px;
  flex-grow: 1;
}

.button {
  height: 40px;
  background-color: var(--primary-color) !important;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-light-variant)
  ) !important;
  text-transform: none !important;
}
.generate__text {
  color: var(--gray-700);
  margin-bottom: 12px;
  font-size: 14px;
  text-align: left;
}
