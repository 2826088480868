.history__section {
  flex: 1;
  overflow: auto;
}
.not_empty {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.history__container {
  padding-left: 20px;
}

.res__count {
  color: #929292;
  font-size: 12px;
  text-align: right;
  margin-right: 20px;
  margin-bottom: 0px;
}
