.basic__chart_section {
  border: 0.5px solid var(--grey-color);
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.basic__message_container {
  display: flex;
  align-items: center;
  color: var(--gray-700);
  margin-bottom: 12px;
  font-size: 14px;
}

.basic__message_container a {
  margin-right: 8px;
  text-decoration: none;
  background: #9367ca3b;
  padding: 4px 8px;
  border-radius: 5px;
  color: #543e86;
  text-transform: none;
}

.basic__report__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  margin: 15px 0px;
  padding-left: 20px;
  border-radius: 5px;
}

.performance_guage__container {
  width: 16%;
  display: flex;
  align-items: center;
  padding: 15px;
}

.full_guage__container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.full_guage__container > div {
  flex: 0 0 13%;
  margin: 20px;
}
.bar_main_container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 25px;
}
.bar_conatainer {
  display: flex;
  width: 500px;
  align-items: center;
}
.rect_bar_container {
  flex: 1;
}
.rect_val {
  margin-right: 10px;
  font-weight: 700;
  color: #df3f36;
  font-size: 25px;
}
.top_five_container {
  /* display: flex;
    align-items: center; */
}
.chart_label {
  /* width: 200px; */
  text-align: center !important;
}

.chart_legend {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 12px;
  border-top: 1px solid rgba(128, 128, 128, 0.254);
  padding-top: 20px;
  margin-top: 20px;
}
.chart_legend_item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 170px;
  text-align: left;
}
.chart_legend_color {
  height: 26px;
  width: 26px;
  background-color: #543e86;
  opacity: 0.5;
  border-radius: 5px;
}
.chart_legend_title {
  font-weight: 800;
}
.chart_legent_range {
  color: grey;
}
.chart_legend_range_title {
  font-weight: 500;
}
