.thumbnail__container {
  border: 0.5px solid #c7c7ca;
  padding: 20px;
  border-radius: 5px;
  page-break-after: always;
}

.thumbnail__img_container {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #f1f1f2;
  justify-content: center;
}

.img__label {
  font-size: 12px;
  color: grey;
  text-align: center;
  margin-top: 5px;
}
