.indicator {
  width: 30px;
  height: 30px;
  background-color: aquamarine;
}

.table_row_head,
.table_row {
  display: flex;
  border-top: 1px solid #ebebeb;
  cursor: pointer;
  font-size: 15px;
  color: #3c2c2c;
}
.table_row:hover {
  background-color: #e3d9f755;
}

.table_col_head,
.table_col {
  flex: 1;
  padding: 0px 20px;
  height: 55px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
}
.table_col:nth-child(2) {
  justify-content: center;
}
.table_col_head {
  font-weight: 700;
  justify-content: center;
}

.details_section {
  max-height: 0px;
  transition: max-height 0.6s;
  overflow: auto;
  height: auto;
  font-size: 14px;
  color: #423163;
}
.expanded_container {
  background-color: #eae6f255;
}
.expanded_row {
  background-color: #eae6f299;
}
.expanded_row .table_col {
  font-weight: 500;
}
.jsx_container {
  padding: 20px;
}
.empty_conatiner {
  text-align: center;
  color: #ebebeb;
  border-top: 1px solid #ebebeb;
}
