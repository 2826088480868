.search__history_section {
  display: flex;
  height: calc(100vh - 91px);
}

.search__container {
  width: 300px;
  padding: 40px 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  overflow: auto;
}
.report__container {
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.history__report {
  width: 890px;
}
.history_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
