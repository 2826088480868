.avatar {
  background-color: var(--primary-color) !important;
}

.email {
  color: var(--grey-color);
  margin-bottom: 10px;
  margin-top: 5px;
}

.logout__button {
  padding: 0px !important;
  color: red !important;
  text-transform: none !important;
  margin-top: 10px !important;
}

.logout__icon {
  transform: rotate(180deg);
  margin-right: 5px;
}