.url_conatainer {
  border: 0.5px solid var(--grey-color);
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}
.url_item {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.url_item p {
  color: rgb(71, 70, 70);
  word-wrap: break-word;
  max-width: 800px;
  text-align: justify;
  margin: 5px 0px;
}
