.metrics__container {
  border: 0.5px solid #c7c7ca;
  padding: 20px;
  border-radius: 5px;
}

.metric__container {
  padding-top: 2rem;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  margin-bottom: 8px;
}
.title_section {
  display: flex;
  align-items: center;
  gap: 5px;
}
.title__text {
  color: var(--gray-700);
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
}
