.http_code_ref_conatainer {
  text-align: left;
}
.http_main_head {
  color: rgb(33, 32, 32);
}
.http_sub_head {
  color: rgb(33, 32, 32);
  font-weight: 500;
}

.http_code_table_container {
  /* background-color: #e7e6eb14; */
  border-radius: 5px;
  border: 1px solid #e7e6ebd1;
}

.http_code_table_col_label,
.http_code_table_col {
  border-bottom: 1px solid #e7e6ebd1 !important;
  min-width: 250px;
  padding: 8px 12px !important;
}
.http_table_label_tag_green {
  background-color: rgb(215 250 215);
  font-size: 13px;
  padding: 3px 10px;
  width: fit-content;
  border-radius: 5px;
  color: green;
}

.http_table_label_tag_red {
  background-color: rgb(250 219 215);
  font-size: 13px;
  padding: 3px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: #9e0505;
}

.http_table_label_tag_blue {
  background-color: rgb(215 247 250);
  font-size: 13px;
  padding: 3px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: #04377c;
}

.http_table_label_tag_yellow {
  background-color: rgb(251 251 199 / 89%);
  font-size: 13px;
  padding: 3px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: #ab940a;
}
