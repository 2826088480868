.container {
}
.loader__container {
  text-align: center;
  padding: 5rem;
}

.img {
  max-width: 340px;
  max-height: 240px;
}
.error_container {
  margin-top: 20px;
}
