.login__container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper__container {
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 2em;
}

.login__button {
  border-color: var(--primary-color) !important;
  text-transform: none !important;
  color: black !important;
  font-size: 16px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  flex-wrap: wrap;
}

.login__text {
  font-size: 20px;
  padding: 1em 0;
}

.copyright__text {
  color: var(--grey-text-color);
  font-size: 12px;
  padding-top: 3em;
}