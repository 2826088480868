.title__container {
  gap: 0.5em;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
}
.header_section_left {
  flex: 1;
}
.time__container,
.url__container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #929292;
}
.url__container {
  font-weight: 700;
  font-size: 16px;
  color: var(--gray-700);
}
.spacer__div {
  flex-grow: 1;
}

.buttons {
  color: var(--black-variant) !important;
  border-color: var(--grey-color) !important;
  outline: var(--grey-shade) solid 1px !important;
}

.report__title__container {
  display: flex;
  border-right: 0;
  background: #bfbbc336;
  border-radius: 5px;
}

.report__title__div {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 5px;
  gap: 10px;
  min-height: 35px;
}

.report__title__div_selected {
  background: white;
}
.report__text {
  cursor: pointer;
  margin: 10px 0px;
}

.report__selected {
  background-color: #f3ec78;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-light-variant)
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.report__details {
  border-top: 0;
}

.advanced__tab_conatiner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.download__button {
  background: #e6e1ef !important;
  color: #58418b !important;
  font-size: 12px !important;
  border: none !important;
}
.download__button__container {
  display: flex;
  align-items: center;
}
