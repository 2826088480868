.title__container {
  margin: 2em 0 1em 0;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.spacer__div {
  flex-grow: 1;
}

.buttons {
  color: var(--black-variant) !important;
  border-color: var(--grey-color) !important;
  outline: var(--grey-shade) solid 1px !important;
}

.report__title__container {
  display: flex;
  border-right: 0;
  background: #bfbbc336;
  border-radius: 5px;
}

.report__title__div {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 5px;
  gap: 10px;
}

.report__title__div_selected {
  background: white;
}
.report__text {
  cursor: pointer;
  margin: 10px 0px;
}

.report__selected {
  background-color: #f3ec78;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-light-variant)
  );
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.report__details {
  border-top: 0;
}

.advanced__tab_conatiner {
  display: flex;
  align-items: center;
  justify-content: center;
}
