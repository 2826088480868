.filter_conatiner {
  display: flex;
  column-gap: 10px;
  color: #543e85;
  margin: 14px 0px;
}
.filter_item {
  background: #e3d9f7;
  padding: 10px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}
.filter_item:hover {
  background: #d6c8f3;
}
.selected {
  background-color: var(--primary-color) !important;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--primary-color-light-variant)
  ) !important;
  text-transform: none !important;
  color: white;
  box-sizing: unset;
}
