.http__success_conatiner {
  height: 400px;
  width: 70%;
  margin-bottom: 40px;
}
.r_table_container {
  border: 1px solid #b4b4bf;
  border-radius: 5px;
}
.r_tabel_head_row {
  background: #392d695e;
}
.r_table_head {
  background-color: #392d69;
  text-align: center !important;
  font-weight: 700;
  text-transform: capitalize;
  padding: 12px !important;
  color: white !important;
}
.r_table_head th {
  color: white !important;
}
.r_table_row_0 {
  background-color: #392d69;
  font-weight: 700;
}
.r_table_row_0 td {
  color: white !important;
}

.r_table_col_0 {
  font-weight: 700;
  color: white !important;
}
.r_tabel_cell {
  text-align: center !important;
  border-right: 1px solid #b4b4bf;
  border-bottom: 1px solid #b4b4bf !important;
  padding: 12px !important;
}
.r_table_first_col {
  font-weight: 700;
  text-align: left !important;
  padding-left: 15px !important;
}
.graph__container {
  display: flex;
  margin-bottom: 40px;
  break-inside: avoid;
}
.graph__description {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray-700);
  margin-bottom: 12px;
  /* text-transform: uppercase; */
  font-size: 14px;
  flex: 1;
  text-align: left;
}

.indicator__circle {
  width: 12px;
  height: 12px;
  background-color: rgb(213, 211, 211);
  border-radius: 50%;
}
.indicator__circle_red {
  width: 12px;
  height: 12px;
  background-color: #ec5e5e;
  border-radius: 50%;
}
.indicator__circle_green {
  width: 12px;
  height: 12px;
  background-color: #055c38;
  border-radius: 50%;
}
.indicator__circle_white {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.r_table_value_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.report__table_main_container {
  break-inside: avoid;
  page-break-before: always;
}
.report__table {
  page-break-inside: avoid;
}
