.pdf__container {
  width: 1000px;
  margin: auto;
}
.pdf_main__title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.pdf_loader_error__container {
  display: flex;
  width: 900px;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
.pdf_sub__title {
  text-align: center;
  color: rgb(87, 5, 149);
}
.subtitle_label {
  color: black;
  font-weight: 700;
}
.roadrunnr__logo {
  text-align: center;
  margin: auto;
  /* padding-top: 20px; */
}
.roadrunnr__logo img {
  height: 40px;
}
.date__header_container {
  margin-top: 15px;
  color: rgb(81, 78, 78);
  font-size: 13px;
  font-weight: 700;
  display: flex;
}
.date__footer_container {
  display: none;
}
.pdf__hidden_values {
  display: none;
}
@media print {
  .date__footer_container {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-bottom: -10px;
  }
}

.site_link {
  margin-right: 8px;
  text-decoration: none;
  background: #9367ca3b;
  padding: 4px 8px;
  border-radius: 5px;
  color: #543e86;
  text-transform: none;
}
.pdf__recommendation_container {
  break-inside: avoid;
  page-break-before: always;
}
.api_summary {
  break-inside: avoid;
}
.date_footer_label {
  margin: 0px;
  float: left;
  margin-right: 10px;
  /* color: black; */
}

.basic_summary {
  page-break-after: always;
}
