.title__container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-align: left;
  break-after: avoid;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.title__help {
  width: 15px !important;
  height: 15px !important;
  color: var(--grey-color);
}

.data__container {
  border: 1px solid var(--grey-color);
  outline: var(--grey-shade) solid 1px;
  padding: 2rem 3rem;
  text-align: left;
}

.child__title {
  font-size: 16px;
  font-weight: 500;
}

.sub__title {
  font-size: 14px;
  font-weight: 500;
}

.suggestion__container {
  margin: 0;
}

.suggestion {
  font-size: 14px;
  font-weight: 400;
  padding: 0.2rem;
}
