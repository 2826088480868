.center__container {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin: 100px 0px;
}
.table {
  min-width: 650px;
  overflow: auto;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.table__head {
  background-color: #392d69;
  color: #fff;
}
.table__head_cell {
  text-align: center !important;
  color: #fff !important;
  border-right: 1px solid #d2d0d0;
}

.table_body_url_cell {
  text-align: center;
  max-width: 350px;
  /* border-right: 1px solid #d2d0d0; */
}

.table_body_url_cell_div {
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}
.url_text {
  max-width: 250px;
  word-wrap: break-word;
}
.chart__container {
  height: 70px;
  width: 70px;
  margin: auto;
}
.table_body_general_cell {
  text-align: center !important;
  /* border-right: 1px solid #d2d0d0; */
}
.table_body_row {
  display: table-row;
  text-decoration: none;
  vertical-align: inherit;
}
.table_body_row:hover {
  background-color: #eeecf5c1;
  cursor: pointer;
}
.footer_message {
  color: var(--gray-700);
  font-size: 16px;
  text-align: center;
}

.history__card {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
.history__card_url {
  font-size: 14px;
  font-weight: 500;
}

.history__card_date {
  color: #929292;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.history__card_chart {
  width: 70px;
  height: 70px;
}
.history__card_text_container {
  flex: 1;
  max-width: 100%;
  word-break: break-all;
}

.mobile__metrics_container {
  display: flex;
  justify-content: center;
}
.mobile__metric_label {
  font-size: 12px;
  color: grey;
  text-align: center;
  font-weight: 600;
}
.mobile__title {
  color: var(--gray-700);
  margin-bottom: 10px;
  /* text-transform: uppercase; */
  font-size: 14px;
  text-align: center;
  font-weight: 800;
  /* margin-left: 20px; */
}
.mobile__details_container {
  padding-bottom: 20px;
  box-sizing: border-box;
}

.mobile__stack_conatainer {
  text-align: center;
}
.mobile__details__link_container {
  text-align: center;
}
.mobile__details__link_container a {
  color: #392d69;
}
.mobile__header {
  padding: 20px;
  text-align: center;
  background-color: #392d69;
  color: white;
  font-weight: 500;
}
.mobile__container {
  border-left: 1px solid #e0e0e0;
  margin: 20px;
}
