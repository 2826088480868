.constants {
  --primary-color: #543e86;
  --primary-color-light-variant: #9b6bd2;
  --primary-outline-color: #aea4c6;
  --fail-text-color: #c00;
  --fail-fill-color: #f33;
  --average-text-color: #c33300;
  --average-fill-color: #fa3;
  /* --pass-text-color: #080; */
  --pass-text-color: #59c014;
  --pass-fill-color: #0c6;
  --black-variant: rgba(0, 0, 0, 0.65);
  --grey-shade: rgba(189, 195, 199, 0.5);
  --grey-color: #b5b5b5;
  --grey-text-color: #616161;
  --gray-700: #616161;
}

.title__text {
  color: var(--gray-700);
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
}

.title__text_bold {
  color: var(--gray-700);
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  text-align: left;
}
.url-selector {
  text-align: left;
}
