.parent__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title__container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.child__title {
  font-size: 16px;
  font-weight: 500;
}

.title__help {
  width: 15px !important;
  height: 15px !important;
  color: var(--grey-color);
}

.subtitle {
  margin-top: -8px;
  font-size: 13px;
  color: var(--grey-text-color);
}

.data__container {
  border: 1px solid var(--grey-color);
  outline: var(--grey-shade) solid 1px;
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  gap: 2em;
}

@media only screen and (max-width: 800px) {
  .data__container {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
}

.url__list__container {
  flex: 1;
}

.webvitals__container {
  flex: 2;
}

.performance__container {
  display: flex;
  justify-content: center;
}

.svg_icon {
  display: none !important;
}

.table,
.table th,
.table td {
  border: 1px solid rgb(203, 213, 225);
  border-collapse: collapse;
  color: var(--black-variant);
  padding: 0.5rem;
}

.thead tr {
  background-color: rgb(243, 244, 246);
}

.url__list {
  border-bottom: 2px solid var(--grey-shade);
  padding: 1rem 0;
  color: var(--grey-text-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  text-overflow: clip;
  white-space: normal;
  word-break: break-word;
}

.url__list:focus {
  color: #1976d2 !important;
}

.url__list:focus svg {
  color: #1976d2 !important;
  display: inline-block !important;
}

.url__list:hover svg {
  color: var(--grey-text-color) !important;
  display: inline-block !important;
}

.url__selected {
  color: #1976d2;
}

.url__selected svg {
  display: inline-block !important;
  color: #1976d2 !important;
}
.section_description {
  text-align: left;
  color: rgb(90, 89, 90);
  margin-top: 20px;
  margin-bottom: 10px;
  /* text-transform: uppercase; */
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}
