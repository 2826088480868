.errorboundary__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--black-variant);
}

.h3__text {
  font-size: 20px;
  margin: 0;
}

.p__text {
  font-size: 14px;
}